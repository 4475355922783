import { Stack } from "@mui/material";

import { Typography } from "../../../components/design-system/Typography";
import { useTranslate } from "../../../hooks/useTranslate";

export const UKThanksForInterest = () => {
  const { t } = useTranslate();
  return (
    <Stack padding={8} gap={2}>
      <Typography variant="h4">{t("dashboard", "signup.thanksForInterest")}</Typography>
      <Typography variant="body2">{t("dashboard", "signup.thanksForInterestDescription")}</Typography>
    </Stack>
  );
};
